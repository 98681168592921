/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&family=Open+Sans&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');
/* @import url('https://fonts.googleapis.com/css?family=Montserrat|Open+Sans|Muli|Quicksand|Lato|Varela+Round&display=swap'); */

/*------------------------------------*
  RESET
*------------------------------------*/
body, div, dl, dt, dd, ul, ol, li,
h1, h2, h3, h4, h5, h6,
pre, form, fieldset, input, textarea,
p, blockquote, th, td { 
	margin: 0;
	padding: 0;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
fieldset, img {
  	border: 0;
}
address, caption, cite, dfn, th, var {
	font-style: normal;
	font-weight: normal;
}
caption, th {
  	text-align: left;
}
h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	font-weight: normal;
}
q:before, q:after {
 	 content: '';
}
abbr, acronym {
  	border: 0;
}

/*------------------------------------*
  MAIN
*------------------------------------*/
html {
	background: #fff;
	color: #333;
	font-family: 'Poppins', '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif';
	font-size: 13px;
}

h1,h2,h3,h4,h5,h6,hgroup,
ul,ol,dd,
p,figure,
pre,table,fieldset,hr {
    margin-bottom: 1rem;
}

p:last-child, ul:last-child {
	margin-bottom: 0;
}


h1,h2,h3,h4,h5,h6 {
	line-height: 1.25em; 
}

h1 {
	font-size: 3em; /* 24px --> 24 ÷ 16 = 1.5 */
	font-weight: 500;
    margin-bottom: 2rem;
}

h2 {
	font-size: 2em; /* 22px --> 22 ÷ 16 = 1.375 */
	font-weight: 500;
	margin-bottom: 2rem;
}
  
h3 {
	font-size: 1.75em; /* 20px --> 20 ÷ 16 = 1.25 */
	font-weight: 500;
}
  
h4 {
	font-size: 1.6em; /* 18px --> 18 ÷ 16 = 1.125 */
	font-weight: 500;
}
  
h5 {
	font-size: 1.3em; /* 16px --> 16 ÷ 16 = 1 */
}

h6 {
	font-size: 1.1em; /* 16px --> 16 ÷ 16 = 1 */
}

ul, p, input, textarea {
	max-width: 30rem; /* target about 65 characters*/
}

p {
	line-height: 1.45em;
}

ul {
	list-style-position: outside;
	padding-left: 22px;
	text-align: left; /** Specify left align since this property can be inherited from parents */
}

#app {
	overflow: none;
}


.ReactModal__Overlay {
    opacity: 0;
	transition: opacity 200ms ease-in-out;

	z-index: 1599;
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
	background-color: rgba(0,0,0,0.5);
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

.ReactModal__Content {
	position: absolute;
	top: 50%;
	left: 50%;
	right: auto;
	bottom: auto;
	margin-right: -50%;
	transform: translate(-50%, -50%);
	background: white;
}


.ReactCollapse--collapse {
	transition: height 500ms;
}